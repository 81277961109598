
.contact {
  margin-top: 42px;
  margin-bottom: 40px;
  .title {
    font-size: 28px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #101010;
  }
  // .name {
  //   margin-top: 20px;
  //   font-weight: 500;
  //   background-color: #2885ff;
  //   padding: 6px;
  //   box-sizing: border-box;
  //   display: inline-block;
  //   border-radius: 2px 8px 8px 8px;
  //   color: #ffffff;
  //   font-size: 14px;  
  //   line-height: 21px;
  //   margin-bottom: 20px;      
  // }
  // .text {
  //   margin-top: 14px;
  //   font-size: 16px;
  //   font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  //   font-weight: 400;
  //   margin-top: 14px;
  // }
  .info-title {
        margin-bottom: 24px;
        margin-top: -24px;
        img {
          width: 8px;
          height: 10px;
          margin-right: 12px;
        }
        .label {
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #101010;
          line-height: 24px;
        }
      }
  .info-consulting {
        .tit {
          background-color: #2885ff;
          padding: 6px;
          box-sizing: border-box;
          display: inline-block;
          border-radius: 2px 8px 8px 8px;
          color: #ffffff;
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 20px;
        }
        .consulting-item {
          margin-bottom: 20px;
          .label {
            font-size: 12px;
            line-height: 18px;
            color: #8896a7;
            margin-right: 12px;
          }

          .value {
            font-size: 12px;
            line-height: 18px;
            color: #404040;
          }
        }
      }
}
